import React from 'react';
import config  from '../config';
import pkg from '../../package.json';
import '../css/footer.css';

export default function Footer() {
	return (
		<div id="footer">
			<span id="version-label">{config.ENV + ' - v' + pkg.version}</span>
			<span id="footer-links">
				<a href="https://wordfinder.yourdictionary.com/scrabble-dictionary/" target="_blank">wordfinder</a>
				<a href="/credits.txt" target="_blank">credits</a>
				<a href="/privacy.html" target="_blank">privacy</a>
				<a href="https://moonstopsoftware.com" target="_blank">moonstopsoftware</a>
			</span>
		</div>
	)
}