import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearQuestComplete } from '../reducers/userReducer';
import { quests } from '../quests';
import { playAudio } from '../audio';
import QuestBar from './QuestBar';
import '../css/questtracker.css';

export default function QuestTracker() {
	const [animClass, setAnimClass] = useState('quest-tracker-hidden');

	const dispatch = useDispatch();
	const stats = useSelector(s => s.user.stats);

	const quest = quests[stats.quest];

	const isComplete = stats.questComplete || stats.questProgress === true;

	useEffect(() => {
		if((stats.questProgress !== 0 && stats.questProgress !== true) || stats.questComplete) {
			const fadeIn = setTimeout(() => {
				setAnimClass('animate__slideInDown');

				if(stats.questComplete)
					playAudio('fanfare');
				else
					playAudio('progress');
			}, 2000);

			const fadeOut = setTimeout(() => {
				setAnimClass('animate__slideOutUp');
				playAudio('open');
				dispatch(clearQuestComplete());
			}, 8000);

			return () => {
				clearTimeout(fadeIn);
				clearTimeout(fadeOut);
			};
		}
	}, [dispatch, stats.questProgress, stats.questComplete]);

	return (
		<div className={'quest-tracker animate__animated ' + animClass}>
			<h2 style={{color: 'violet', whiteSpace: 'nowrap'}}>{isComplete ? <>complete! <span style={{color: 'gold'}}>+{Math.round(quest.xp*100)}xp</span></> : 'quest progress'}</h2>
			<QuestBar defaultProgress={1}/>
		</div>
	);
}