import React from 'react';
import ReactModal from 'react-modal';
import { playAudio } from '../../audio';

export default function ConfirmModal(props) {
	function handleOpen() {
		if(props.onOpen)
			props.onOpen();
	}

	function handleCancel() {
		playAudio('cancel');
		props.onCancel();
	}

	function handleConfirm() {
		props.onConfirm();
	}

	function handleAlternative() {
		props.onAlternative();
	}

	const closeTimeoutMS = props.closeTimeoutMS !== undefined ? props.closeTimeoutMS : 100;

	return (
		<ReactModal isOpen={props.isOpen} onAfterOpen={handleOpen} className={'modal ' + props.modalClass} overlayClassName="overlay" contentLabel="Confirm Action" closeTimeoutMS={closeTimeoutMS}>
			<h2 style={{margin: 0}}>{props.title || 'are you sure?'}</h2>
			{props.icon ? <props.icon size={32} style={{position: 'absolute', top: 0, left: 0, transform: 'translate(-60%, -60%)'}}/> : null}
			<div style={{margin: '12px 0px'}}>{props.text}</div>
			{props.tip ? <div style={{margin: '0 0 12px 0', color: 'cyan', fontStyle: 'italic', whiteSpace: 'nowrap'}}>{props.tip}</div> : null}
			<div style={{display: 'flex'}}>
				<button onClick={handleCancel}>{props.cancelText || 'cancel'}</button>
				<button className="hero-btn" onClick={handleConfirm}>{props.confirmText || 'confirm'}</button>
			</div>
			{props.alternativeText ? <button className="primary-btn" onClick={handleAlternative}>{props.alternativeText}</button> : null}
		</ReactModal>
	);
}