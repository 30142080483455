import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { FaStar } from 'react-icons/fa';
import LevelBar from '../LevelBar';
import { playAudio } from '../../audio';
import { clearLvlUp } from '../../reducers/userReducer';
import { lvls } from '../../config';

export default function LevelUpModal() {
	const dispatch = useDispatch();

	const stats = useSelector(s => s.user.stats);
	const isGameOver = useSelector(s => s.settings.isGameOverModalOpen);

	const lvl = lvls[Math.floor(stats.lvl)];

	const isOpen = stats.lvlUp && !stats.questComplete && !isGameOver;

	useEffect(() => {
		if(stats.lvlUp && isOpen)
			playAudio('lvlup');
	}, [stats.lvlUp, isOpen]);

	function handleClose() {
		dispatch(clearLvlUp());
		playAudio('open');
	}

	return (
		<ReactModal isOpen={isOpen} className={'modal modal-hero animate__animated animate__jackInTheBox'} overlayClassName="overlay" contentLabel="Level Up" closeTimeoutMS={100}>
			<h2 className="animate__animated animate__flip" style={{color: 'gold'}}>level up!</h2>
			<div style={{position: 'absolute', top: 0, left: 0, transform: 'translate(-36px, -40px)'}}>
				<FaStar className="animate__animated animate__zoomInDown outer-star-anim" size={64} color="gold"/>
				<FaStar className="animate__animated animate__zoomInDown inner-star-anim" size={48} style={{position: 'absolute', left: 8, top: 8}} color="yellow"/>
			</div>
			<div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
				<LevelBar/>
				<div style={{padding: 8}}>
					you are now <span style={{fontWeight: 'bold'}}>{lvl.title}!</span>
				</div>
			</div>
			<button className="hero-btn" onClick={handleClose} style={{marginBottom: 0}}>continue</button>
		</ReactModal>
	);
}