const rules = {
	PUBLIC: true,
	PLAYERS: 2,
	GRID_SIZE: 11,
	MAX_LETTERS: 7,
	MAX_VOWELS: 3,
	BONUS: {
		DL: [14, 18, 34, 42, 78, 102, 86, 106],
		DW: [24, 30, 96, 90],
		TL: [5, 65, 115, 55],
		TW: [1, 11, 9, 21, 109, 119, 99, 111],
	},
	LETTERS: {
		a: { count: 4, points: 1 },
		b: { count: 1, points: 2 },
		c: { count: 2, points: 2 },
		d: { count: 3, points: 2 },
		e: { count: 6, points: 1 },
		f: { count: 1, points: 5 },
		g: { count: 2, points: 3 },
		h: { count: 1, points: 4 },
		i: { count: 4, points: 1 },
		j: { count: 1, points: 7 },
		k: { count: 1, points: 6 },
		l: { count: 2, points: 1 },
		m: { count: 1, points: 3 },
		n: { count: 2, points: 1 },
		o: { count: 3, points: 1 },
		p: { count: 2, points: 4 },
		q: { count: 1, points: 9 },
		r: { count: 3, points: 1 },
		s: { count: 4, points: 1 },
		t: { count: 2, points: 1 },
		u: { count: 2, points: 2 },
		v: { count: 1, points: 4 },
		w: { count: 1, points: 4 },
		x: { count: 1, points: 8 },
		y: { count: 2, points: 4 },
		z: { count: 1, points: 9 },
		_: { count: 1, points: 0 }
	},
	TIMEOUT: 180,
	VOTE_TIMEOUT: 30,
	PLAY_ORDER: ['1', '0'],
	WIN_XP: 0.25,
	DRAW_XP: 0.1
}

export default rules;