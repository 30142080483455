import { createSlice } from '@reduxjs/toolkit';
import { requestNotifications, cancelNotifications } from '../notifications';
import { getQuest } from '../quests';

const initialState = {
	id: null,
	token: null,
	nickname: '',
	profile: {
		name: null,
		pic: null
	},
	stats: {
		lvl: 0,
		lvlUp: false,
		winCount: 0,
		lossCount: 0,
		drawCount: 0,
		quest: getQuest(),
		questProgress: 0,
		questComplete: false
	},
	rooms: {},
	leaders: {},
	region: 'US',
	count: 0,
	bot: {},
	botRooms: {}
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (s, a) => {
			s.id = a.payload.userId;
			s.token = a.payload.userToken;

			if(s.id)
				requestNotifications(false);
			else
				cancelNotifications();
		},
		setNickname: (s, a) => {
			s.nickname = a.payload
		},
		setProfile: (s, a) => {
			s.profile = {
				name: a.payload.name, 
				pic: a.payload.pic
			}
		},
		setStats: (s, a) => {
			s.stats = {
				...s.stats,
				lvl: a.payload.lvl,
				lvlUp: s.stats.lvlUp || Math.floor(a.payload.lvl) > Math.floor(s.stats.lvl),
				winCount: a.payload.w,
				lossCount: a.payload.l,
				drawCount: a.payload.d,
				quest: a.payload.quest,
				questProgress: a.payload.questProgress,
				questComplete: s.stats.questComplete || (s.stats.questProgress !== true && a.payload.questProgress === true)
			}
		},
		clearLvlUp: (s, a) => {
			s.stats.lvlUp = false;
		},
		clearQuestComplete: (s, a) => {
			s.stats.questComplete = false;
		},
		setLeaders: (s, a) => {
			s.leaders = a.payload || {};
		},
		setRegion: (s, a) => {
			s.region = a.payload || 'US';
		},
		setCount: (s, a) => {
			s.count = a.payload || 0;
		},
		setRooms: (s, a) => {
			s.rooms = a.payload
		},
		patchRooms: (s, a) => {
			for(let roomId in a.payload) {
				if(a.payload[roomId])
					s.rooms[roomId] = a.payload[roomId];
				else
					delete s.rooms[roomId];
			}
		},
		clearUser: (s) => {
			cancelNotifications();
			return initialState;
		},
		setBot: (s, a) => {
			s.bot = {
				id: a.payload.userId,
				token:  a.payload.userToken
			}
		},
		setBotRoom: (s, a) => {
			s.botRooms[a.payload.roomId] = a.payload;
		}
	}
});

export const { setNickname, setProfile, setStats, setRegion, setCount, setLeaders, setRooms, patchRooms, setUser, clearUser, clearLvlUp, clearQuestComplete, setBot, setBotRoom } = userSlice.actions;

export default userSlice.reducer;