import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import settingsReducer, { setMenuOpen } from './reducers/settingsReducer';
import { muteMusic, muteSFX } from './audio';
import pkg from '../package.json';

const version = (localStorage.getItem('version') || '');
const state = JSON.parse(localStorage.getItem('reduxState') || '{}');

const oldVer = version.split('.');
const newVer = pkg.version.split('.');

const compatible = oldVer.length === 3 && oldVer[0] === newVer[0] && oldVer[1] === newVer[1];

const store = configureStore({
	reducer: {
		user: userReducer,
		settings: settingsReducer
	},
	preloadedState: compatible ? state : {}
});

store.subscribe(() => {
	const state = store.getState();

	muteMusic(state.settings.isMuteMusic);
	muteSFX(state.settings.isMuteSFX);

	localStorage.setItem('reduxState', JSON.stringify(state));
});

localStorage.setItem('version', pkg.version);

store.dispatch(setMenuOpen(false));

export default store;
