export const goals = {
	NOUNS: 0,
	PRONOUNS: 1,
	ADJECTIVES: 2,
	VERBS: 3,
	WINS: 4,
	STREAK: 5,
	POINTS: 6,
	LENGTH: 7,
	SCORE: 8
};

export const quests = [
	{ goal: goals.NOUNS, count: 3, xp: 0.5 },
	{ goal: goals.PRONOUNS, count: 3, xp: 0.5 },
	{ goal: goals.ADJECTIVES, count: 3, xp: 0.5 },
	{ goal: goals.VERBS, count: 3, xp: 0.5 }
];

export function getQuestTs() {
	const now = new Date();
	return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()).getTime();
}

export function getQuest() {
	const now = new Date();
	const seed = Number('' + now.getUTCFullYear() + now.getUTCMonth() + now.getUTCDate());

	let rand = Math.sin(seed) * 10000;
	rand = rand - Math.floor(rand);
	
	return Math.round((quests.length - 1) * rand);
}
