import React from 'react';
import ReactModal from 'react-modal';
import { FaGraduationCap } from 'react-icons/fa';
import { playAudio } from '../../audio';
import { useDispatch, useSelector } from 'react-redux';
import { setLearnModalOpen } from '../../reducers/settingsReducer';

export default function LearnModal() {
	const dispatch = useDispatch();
	const isOpen = useSelector(s => s.settings.isLearnModalOpen);

	function handleClose() {
		dispatch(setLearnModalOpen(false));
		playAudio('click');
	}

	return (
		<ReactModal isOpen={isOpen} className={'modal modal-wide'} overlayClassName="overlay" contentLabel="Learn" onRequestClose={handleClose} closeTimeoutMS={100}>
			<h2>learn to play</h2>
			<FaGraduationCap size={48} style={{position: 'absolute', top: 0, left: 0, transform: 'translate(-60%, -60%)'}}/>
			<div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', width: '100%', maxWidth: 480}}>
				<h4>1. goal</h4>
				<p>score the most points by playing connected words on the board each turn, using your available letters.</p>
				<h4>2. letters</h4>
				<p>there are 55 letters (80 in party mode), each with different point values. blank tiles '_' may be assigned any letter, but score 0 points.</p>
				<h4>3. victory</h4>
				<p>the game ends after the first player plays their final letter. all other players have their remaining letters deducted from their score.</p>
			</div>
			<button onClick={handleClose} style={{marginBottom: 0}}>got it!</button>
		</ReactModal>
	);
}