import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { setLeaderboardModalOpen } from '../../reducers/settingsReducer';
import { FaTrophy } from 'react-icons/fa';
import { MdClose, MdSwapHoriz } from 'react-icons/md';
import { NimbleEmoji } from 'emoji-mart/dist-modern/index.js';
import twitterData from 'emoji-mart/data/twitter.json';
import { playAudio } from '../../audio';
import '../../css/leaderboard.css';

const ranks = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th'];

const placeholders = new Array(10);
placeholders.fill({nickname: '---', score: '#'});

export default function LeaderboardModal() {
	const dispatch = useDispatch();

	const isOpen = useSelector(s => s.settings.isLeaderboardModalOpen);
	const leaders = useSelector(s => s.user.leaders);

	const [view, setView] = useState('monthly');

	const allLeaders = leaders.all?.concat(placeholders.slice(0, 10 - leaders.all.length)) || [];
	const monthlyLeaders = leaders.monthly?.concat(placeholders.slice(0, 10 - leaders.monthly.length)) || [];

	function handleSwitch() {
		setView(view === 'monthly' ? 'all time' : 'monthly');
		playAudio('click');
	}

	function handleClose() {
		dispatch(setLeaderboardModalOpen(false));
		playAudio('click');
	}

	function getColor(rank) {
		if(rank === 0)
			return 'gold';
		else if(rank === 1)
			return 'silver';
		else if(rank === 2)
			return 'DarkGoldenRod';

		return 'var(--light-color)';
	}

	const viewedLeaders = view === 'monthly' ? monthlyLeaders : allLeaders;

	return (
		<ReactModal isOpen={isOpen} className="modal" overlayClassName="overlay" contentLabel="Leaderboard" onRequestClose={handleClose} closeTimeoutMS={100}>
			<h2 style={{whiteSpace: 'nowrap', color: 'gold'}}>{`${view} leaders`}</h2>
			<FaTrophy size={48} color="gold" style={{position: 'absolute', top: 0, left: 0, transform: 'translate(-60%, -60%)'}}/>
			<div className="leaders">
				{viewedLeaders ? viewedLeaders.map((l, i) =>
				[
					<span key={i+'r'} className="leader-rank" style={{color: getColor(i)}}>{ranks[i]}.</span>,
					<span key={i+'t'} className="leader-tag" style={{color: getColor(i)}}><NimbleEmoji emoji={'flag-' + l.region} set="twitter" data={twitterData} size={24} sheetSize={64}/><span>{l.nickname}</span></span>,
					<span key={i+'s'} className="leader-score" style={{color: getColor(i)}}>{l.score}</span>
				]) : null}
			</div>
			<div style={{display: 'flex', width: '100%', marginTop: 8}}>
				<button className="primary-btn" onClick={handleSwitch} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flexGrow: 1, margin: 0, padding: 0}}>
					<span>{view === 'monthly' ? 'all time' : 'monthly'}</span><MdSwapHoriz size={24} style={{verticalAlign: 'middle'}}/>
				</button>
				<button className="icon-btn" onClick={handleClose} style={{margin: 0, marginLeft: 16}}>
					<MdClose size={24}/>
				</button>
			</div>
		</ReactModal>
	);
}