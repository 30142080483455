import React from 'react';
import { useSelector } from 'react-redux';
import { NimbleEmoji } from 'emoji-mart/dist-modern/index.js';
import twitterData from 'emoji-mart/data/twitter.json';
import ProgressBar from './ProgressBar';
import { lvls } from '../config';

export default function LevelBar() {
	const stats = useSelector(s => s.user.stats);

	const nowLvl = Math.floor(stats.lvl);
	const lvl = lvls[nowLvl];
	const nxtLvl = nowLvl + 1 < lvls.length ? lvls[nowLvl + 1] : lvl;

	const lvlProgress = (stats.lvl % 1);

	const curXP = Math.round(100 * (lvlProgress * lvl.scale));
	const maxXP = 100 * lvl.scale;

	const desc = nowLvl < lvls.length-1 ? `${curXP}/${maxXP}XP` : 'max level';

	return (
		<ProgressBar now={lvlProgress}>
			<div id="progress-txt">
				<NimbleEmoji emoji={lvl.emoji} set="twitter" data={twitterData} size={24} sheetSize={64}/>
				<div style={{whiteSpace: 'nowrap'}}>{`level ${nowLvl+1} - ${desc}`}</div>
				<NimbleEmoji emoji={nxtLvl.emoji} set="twitter" data={twitterData} size={24} sheetSize={64}/>
			</div>
		</ProgressBar>
	)
}