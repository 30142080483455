import React from 'react';
import ReactModal from 'react-modal';
import { IoMdStats } from 'react-icons/io';
import { playAudio } from '../../audio';
import { useDispatch, useSelector } from 'react-redux';
import { setStatsModalOpen } from '../../reducers/settingsReducer';
import { lvls } from '../../config';

export default function StatsModal() {
	const dispatch = useDispatch();
	
	const stats = useSelector(s => s.user.stats);
	const isOpen = useSelector(s => s.settings.isStatsModalOpen);

	const totalGames = stats.winCount + stats.lossCount + stats.drawCount;

	function handleClose() {
		dispatch(setStatsModalOpen(false));
		playAudio('click');
	}

	return (
		<ReactModal isOpen={isOpen} className={'modal'} overlayClassName="overlay" contentLabel="Stats" onRequestClose={handleClose} closeTimeoutMS={100}>
			<h2 style={{marginBottom: 0}}>stats</h2>
			<IoMdStats size={48} style={{position: 'absolute', top: 0, left: 0, transform: 'translate(-60%, -60%)'}}/>
			<div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', width: '100%', minWidth: 160, margin: '4px 0 12px 0'}}>
				<div className="stat">win/loss/draw: <span>{`${stats.winCount}/${stats.lossCount}/${stats.drawCount}`}</span></div>
				<div className="stat">win ratio: <span>{(totalGames === 0 ? 0 : (stats.winCount / totalGames).toFixed(2))}</span></div>
				<div className="stat">total games: <span>{totalGames}</span></div>
				<div className="stat">level progress: <span>{Math.floor(stats.lvl)+1 + '/' + lvls.length}</span></div>
			</div>
			<button onClick={handleClose} style={{marginBottom: 0}}>close</button>
		</ReactModal>
	);
}