import standard from './standard';

const rules = {
	...standard,
	PUBLIC: false,
	PLAYERS: 4,
	GRID_SIZE: 13,
	BONUS: {
		DL: [106, 134, 138, 114, 62, 34, 30, 54],
		DW: [132, 140, 36, 28],
		TL: [6, 90, 162, 78],
		TW: [1, 13, 11, 25, 155, 167, 157, 143],
	},
	LETTERS: {
		a: { count: 6, points: 1 },
		b: { count: 2, points: 2 },
		c: { count: 3, points: 2 },
		d: { count: 4, points: 2 },
		e: { count: 8, points: 1 },
		f: { count: 1, points: 5 },
		g: { count: 3, points: 3 },
		h: { count: 2, points: 4 },
		i: { count: 5, points: 1 },
		j: { count: 1, points: 7 },
		k: { count: 2, points: 6 },
		l: { count: 4, points: 1 },
		m: { count: 2, points: 3 },
		n: { count: 4, points: 1 },
		o: { count: 4, points: 1 },
		p: { count: 2, points: 4 },
		q: { count: 1, points: 9 },
		r: { count: 5, points: 1 },
		s: { count: 6, points: 1 },
		t: { count: 4, points: 1 },
		u: { count: 3, points: 2 },
		v: { count: 1, points: 4 },
		w: { count: 1, points: 4 },
		x: { count: 1, points: 8 },
		y: { count: 2, points: 4 },
		z: { count: 1, points: 9 },
		_: { count: 2, points: 0 }
	},
	TIMEOUT: -1,
	PLAY_ORDER: ['0', '1', '2', '3'],
	WIN_XP: 0,
	DRAW_XP: 0
}

export default rules;
