import React from 'react';
import { useSelector } from 'react-redux';
import { NimbleEmoji } from 'emoji-mart/dist-modern/index.js';
import twitterData from 'emoji-mart/data/twitter.json';
import { goals, quests } from '../quests';
import ProgressBar from './ProgressBar';

export default function QuestBar(props) {
	const stats = useSelector(s => s.user.stats);

	let label = "quest - complete!\ncheck tomorrow";
	let progress = props.defaultProgress || 0;

	if(stats.questProgress !== true && stats.quest in quests) {
		const quest = quests[stats.quest];

		label = "quest - ";
		progress = stats.questProgress === true ? 1 : stats.questProgress / quest.count;

		switch(quest.goal) {
			case goals.NOUNS: label += `nouns\n${stats.questProgress}/${quest.count} played`; break;
			case goals.PRONOUNS: label += `pronouns\n${stats.questProgress}/${quest.count} played`; break;
			case goals.ADJECTIVES: label += `adjectives\n${stats.questProgress}/${quest.count} played`; break;
			case goals.VERBS: label += `verbs\n${stats.questProgress}/${quest.count} played`; break;
			case goals.WINS: label += `${stats.questProgress}/${quest.count} wins`; break;
			case goals.STREAK: label += `win ${quest.count} games in a row`; break;
			case goals.POINTS: label += `play a\n${quest.count} points word`; break;
			case goals.LENGTH: label += `play a\n${quest.count} letter word`; break;
			case goals.SCORE: label += `finish with\nscore of atleast ${quest.count}`; break;
			default: break;
		}
	}

	return (
		<ProgressBar now={progress} fillStyle={{backgroundColor: 'violet'}}>
			<div id="progress-txt">
				<NimbleEmoji emoji="crossed_swords" set="twitter" data={twitterData} size={24} sheetSize={64}/>
				<div style={{lineHeight: '16px'}}>{label}</div>
				<NimbleEmoji emoji={"star"} set="twitter" data={twitterData} size={24} sheetSize={64}/>
			</div>
		</ProgressBar>
	)
}