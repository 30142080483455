import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { leaveRoom } from '../../apis/api';
import { playAudio } from '../../audio';
import ConfirmModal from '../modals/ConfirmModal';
import RoomItem from '../RoomItem';
import '../../css/gamescard.css';

export default function GamesCard(props) {
	const history = useHistory();
	const location = useLocation();
	const userRooms = useSelector(s => s.user.rooms);

	const rooms = Object.values(userRooms).reverse();

	const [leaveRoomId, setLeaveRoomId] = useState(null);

	function handleLeave() {
		leaveRoom(leaveRoomId).catch(console.warn);

		if(location.pathname.includes(leaveRoomId))
			history.push('/');

		setLeaveRoomId(null);
		playAudio('cancel');
	}

	let leaveRoomPlaying = false;

	if(leaveRoomId) {
		const leaveRoom = userRooms[leaveRoomId];

		if(leaveRoom)
			leaveRoomPlaying = leaveRoom.players.every(p => p.name);
	}

	return (
		<div id="games-card" className="card">
			<h3>your games</h3>
			<Scrollbars className="games-list" renderThumbVertical={p => <div {...p} className="scroll-thumb"/>} renderTrackVertical={p => <div {...p} className="scroll-track"/>}>
				<span style={{position: 'absolute', color: 'var(--cell-color)', fontWeight: '1000', left: '50%', transform: 'translateX(-50%)', whiteSpace: 'nowrap'}}>(^.^)_/</span>
				<div style={{paddingRight: 16}}>
					{rooms.map(r => <RoomItem key={r.roomId} room={r} onSelect={props.onRoomSelect} onLeave={setLeaveRoomId}/>)}
				</div>
			</Scrollbars>
			<ConfirmModal isOpen={leaveRoomId !== null} onConfirm={handleLeave} onCancel={() => setLeaveRoomId(null)} text={leaveRoomPlaying ? 'you will forfeit the game' : 'you will leave the queue'} closeTimeoutMS={0}/>
		</div>
	);
}