import React from 'react';
import '../css/progressbar.css';

export default function ProgressBar(props) {
	return (
		<div className="progress-bar" style={props.style}>
			{props.children}
			<div className="progress-fill" style={{...props.fillStyle, width: (props.now * 100) + '%'}}/>
		</div>
	)
}