import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DotLoader from 'react-spinners/DotLoader';
import { TiArrowForward } from 'react-icons/ti';
import { FaUserTimes } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { playAudio } from '../audio';
import config from '../config';
import '../css/roomitem.css';

export default function RoomItem(props) {
	const history = useHistory();
	const location = useLocation();

	const isMenuOpen = useSelector(s => s.settings.isMenuOpen);
	
	const rules = config.RULES[props.room.setupData.ruleset];
	const isVs = rules.PLAYERS === 2;

	const players = props.room.players;
	const playerCount = players.filter(p => p.name).length;

	const [preload, setPreload] = useState(isMenuOpen);
	const loading = (preload || playerCount < rules.PLAYERS) && !props.room.isGameOver;
	const isActive = location.pathname.replace('/g/', '') === props.room.roomId;

	const title = players.filter(p => p.id !== props.room.playerId && p.name).map(p => p.name).join(', ');

	useEffect(() => {
		const preloadTimeout = setTimeout(() => setPreload(false), 1000);
		return () => clearTimeout(preloadTimeout);
	})

	function handleJoin() {
		if(props.onSelect)
			props.onSelect(props.room.roomId);
		else {
			playAudio('click');
			history.push('/g/' + props.room.roomId);
		}
	}

	async function handleLeave() {
		if(props.onLeave)
			props.onLeave(props.room.roomId);
			
		playAudio('click');
	}

	let status = 'vs. ' + title;

	if(loading)
		status = isVs ? (rules.PUBLIC ? 'finding best match...' : 'waiting for friend...') : 'gathering party...';

	if(loading && props.room.setupData.isRematch)
		status = 'requesting rematch...';

	if(props.room.isGameOver && title === '')
		return null;

	return (
		<div className="room-item">
			<button className="icon-btn" onClick={handleLeave} aria-label="leave">
				<FaUserTimes/>
			</button>
			<div className={'room-name' + (isVs ? '' : ' party-room')}>
				{status}
			</div>
			{
				loading ? <DotLoader size={32} color={'violet'}/>
				: (!isActive
				? (<button className={((props.room.isTurn || props.room.isGameOver) ? 'notification-dot' : '') + ' secondary-btn icon-btn'} onClick={handleJoin} aria-label="join"><TiArrowForward/></button>)
				: <MdLocationOn color="cyan" size={40}/>)
			}
		</div>
	);
}