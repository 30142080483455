import standard from './rules/standard';
import friendly from './rules/friendly';
import party from './rules/party';

//const env = process.env.REACT_APP_ENV || (process.env.NODE_ENV === 'production' ? 'live' : 'dev'); //live, dev or local
const env = 'live';
const port = env === 'live' ? 8080 : 8000;
const lobbyPort = env === 'live' ? 8085 : 8005;
const uri = env === 'local' ? ('http://' + (typeof window !== 'undefined' ? window.location.hostname : 'localhost') + ':' + port) : 'https://api.points4words.io';

export const rulesets = {
	STANDARD: 'standard',
	FRIENDLY: 'friendly',
	PARTY: 'party'
};

export const playerEvents = {
	WORD_PLAYED: 0,
	QUEST_COMPLETE: 1,
	GAME_COMPLETE: 2
};

export const outcomes = {
	WIN: 0,
	LOSS: 1,
	DRAW: 2
};

export const lvls = [
	{title: 'egg', emoji: 'egg', scale: 0.5},
	{title: 'hactchling', emoji: 'hatching_chick', scale: 1},
	{title: 'frog', emoji: 'frog', scale: 1.25},
	{title: 'duck', emoji: 'duck', scale: 1.5},
	{title: 'turtle', emoji: 'turtle', scale: 1.5},
	{title: 'owl', emoji: 'owl', scale: 2},
	{title: 'eagle', emoji: 'eagle', scale: 2},
	{title: 'lion', emoji: 'lion_face', scale: 4},
	{title: 'bear', emoji: 'bear', scale: 4},
	{title: 'whale', emoji: 'whale', scale: 5}
];

const config = {
	NAME: 'points-for-words',
	ENV: env,
	URI: uri,
	API_PORT: port,
	LOBBY_API_PORT: lobbyPort,
	MAX_MSG_LENGTH: 8,
	LONG_POLL_TIMEOUT: 25000,
	PING_TIMEOUT: 2000,
	RULES: {
		[rulesets.STANDARD]: standard,
		[rulesets.FRIENDLY]: friendly,
		[rulesets.PARTY]: party
	},
	PUSH_PUBLIC_KEY: 'BLIwjjB9GrBsBHeEggcBM0YGDZiDknWWZWIpDHSA4BjzM6acrQHlR0_o7N4z8Pu0ZVRHPbs26EfryHfzQwiKAFA'
};

export default config;
