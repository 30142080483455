import React, { useRef, useEffect } from 'react';
import { useMedia, useFavicon } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { TiHome } from 'react-icons/ti';
import { MdSettings } from 'react-icons/md';
import { FaStepBackward } from 'react-icons/fa';
import Menu from 'react-burger-menu/lib/menus/slide';
import { createRoom } from '../apis/api';
import { playAudio } from '../audio';
import { setMenuOpen, setSettingsModalOpen } from '../reducers/settingsReducer';
import ProfileCard from './cards/ProfileCard';
import GamesCard from './cards/GamesCard';
import { rulesets } from '../config';
import '../css/sidemenu.css';

export default function Sidemenu() {
	const dispatch = useDispatch();

	const userRooms = useSelector(s => s.user.rooms);
	const isMenuOpen = useSelector(s => s.settings.isMenuOpen);

	const history = useHistory();
	const location = useLocation();

	const isMobile = useMedia('(max-width: 768px)');
	const quickClose = useRef(false);

	const thisGame = location.pathname.replace('/g/', '');

	const isLoading = userRooms[thisGame]?.players.some(p => !p.name);
	const hasRoomIsTurn = Object.values(userRooms).some(r => (r.isTurn || r.isGameOver) && !location.pathname.includes(r.roomId) && r.players.every(p => p.name));
	const hasRoomWaiting = Object.values(userRooms).some(r => !r.isGameOver && r.players.filter(p => p.name).length <= 1);
	const showNotificationDot = !isLoading && (hasRoomIsTurn || (hasRoomWaiting && location.pathname === '/'));

	useFavicon(showNotificationDot ? (window.location.origin + '/favicon-dot.svg') : (window.location.origin + '/favicon.svg'))

	useEffect(() => {
		quickClose.current = false;
	}, [isMenuOpen]);

	function handleHomeClick() {
		quickClose.current = true;
		dispatch(setMenuOpen(false));

		playAudio('click');

		history.push('/');
	}

	async function handleRoomSelect(roomId) {
		quickClose.current = true;
		dispatch(setMenuOpen(false));

		await Promise.resolve();

		playAudio('click');

		history.push('/g/' + roomId);
	}

	async function handleNewGameClick() {
		await createRoom(rulesets.STANDARD);

		playAudio('click');
	}

	function handleSettingsClick() {
		dispatch(setSettingsModalOpen(true));
		playAudio('click');
	}

	function handleCloseClick() {
		dispatch(setMenuOpen(false));
		playAudio('drop');
	}

	function handleStateChange(s) {
		dispatch(setMenuOpen(s.isOpen));

		if(s.isOpen)
			playAudio('open');
	}

	const buttonClassName = showNotificationDot ? 'notification-dot burger-dot' : '';

	const styles = {
		bmBurgerButton: {
			display: (location.pathname !== '/' || isMobile) ? 'block' : 'none'
		}
	}

	if(quickClose.current) {
		styles['bmMenuWrap'] = { 
			transition: 'all 0s'
		};
	}
	
	return (
		<div id="sidemenu-wrapper">
			<Menu isOpen={isMenuOpen} onStateChange={handleStateChange} customCrossIcon={false} width={isMobile ? 308 : 324} burgerButtonClassName={buttonClassName} styles={styles} disableAutoFocus>
				<ProfileCard useGoogle={(location.pathname !== '/' || isMobile)}/>
				<GamesCard onRoomSelect={handleRoomSelect}/>
				<div id="buttons-wrapper">
					<button className="primary-btn icon-btn" onClick={handleHomeClick} aria-label="home">
						<TiHome/>
					</button>
					<button className="secondary-btn" onClick={handleNewGameClick} disabled={hasRoomWaiting}>
						+ new game
					</button>
					<button className="icon-btn" onClick={handleSettingsClick} aria-label="settings">
						<MdSettings/>
					</button>
					<button className="icon-btn" onClick={handleCloseClick} aria-label="close menu">
						<FaStepBackward/>
					</button>
				</div>
			</Menu>
		</div>
	);
}