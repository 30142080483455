import React, { useEffect, useMemo, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { requestNotifications } from '../notifications';
import { getRooms, setPing, clearPing } from '../apis/api';
import { setRooms, patchRooms, setStats } from '../reducers/userReducer';
import config from '../config';
import Home from './Home';
import Footer from './Footer';
import Sidemenu from './Sidemenu';
import SettingsModal from './modals/SettingsModal';
import LeaderboardModal from './modals/LeaderboardModal';
import LearnModal from './modals/LearnModal';
import StatsModal from './modals/StatsModal';
import NotificationsModal from './modals/NotificationsModal';
import LevelUpModal from './modals/LevelUpModal';
import QuestTracker from './QuestTracker';
import bg from '../media/img/blocks.svg';
import 'animate.css';
import '../css/app.css';

const Game = lazy(() => import('./Game'));

export function isIframe(origin = '') {
    try {
		return window.self !== window.top && window.top.location.origin.includes(origin);
    } catch(e) {
		return true;
    }
}

export default function App() {
	const dispatch = useDispatch();

	const user = useSelector(s => s.user);
	const hasRoomWaiting = useMemo(() => Object.values(user.rooms).some(r => r.players.filter(p => p.name).length <= 1), [user.rooms]);

	useMount(() => {
		if(user.id && user.token)
			requestNotifications(false, true);
	});

	useEffect(() => {
		const abortFetch = new AbortController();
		const signal = abortFetch.signal;

		async function longPoll() {
			let firstPoll = true;

			while(!signal.aborted) {
				try {
					const update = await getRooms(firstPoll, signal);

					if(update.type === 'full')
						dispatch(setRooms(update.rooms));
					else if(update.type === 'patch')
						dispatch(patchRooms(update.rooms));
					
					dispatch(setStats(update.stats));

					if(firstPoll)
						firstPoll = false;
				}
				catch(e) {
					console.warn(e);
					await new Promise(res => setTimeout(res, 3000));
				}
			}
		}

		if(user.token)
			longPoll();

		return () => abortFetch.abort();
	}, [user.token, user.name, dispatch]);

	useEffect(() => {
		if(hasRoomWaiting && (!('Notification' in window) || Notification.permission === 'denied' || isIframe())) {
			const pingInterval = setInterval(setPing, config.PING_TIMEOUT);

			setPing();

			return () => {
				clearInterval(pingInterval);
				clearPing();
			}
		}
	}, [hasRoomWaiting]);

	return (
		<BrowserRouter>
			<div id="app">
				<div id="scroll-bg" style={{backgroundImage: `url(${bg})`}}/>
				<Suspense fallback={null}>
				<Switch>
					<Route path="/g/:roomId" exact>
						<Game/>
					</Route>
					<Route path="/">
						<Home/>
					</Route>
				</Switch>
				</Suspense>
				<Footer/>
				<Sidemenu/>
				<SettingsModal/>
				<LeaderboardModal/>
				<LearnModal/>
				<StatsModal/>
				<NotificationsModal/>
				<LevelUpModal/>
				<QuestTracker/>
			</div>
		</BrowserRouter>
	);
};
