import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import './css/index.css';
import store from './store';
import App from './components/App';
import * as serviceWorker from './registerServiceWorker';

const root = document.getElementById('root');

ReactModal.setAppElement(root);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	root
);

serviceWorker.register({
	onUpdate: swr => swr.unregister().then(() => {
		window.location.reload();
	})
});
