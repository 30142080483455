import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { playAudio } from '../../audio';
import { getAuthGoogle } from '../../apis/api';
import { clearUser, setProfile } from '../../reducers/userReducer';
import { setStatsModalOpen } from '../../reducers/settingsReducer';
import { IoMdStats } from 'react-icons/io';
import LevelBar from '../LevelBar';
import QuestBar from '../QuestBar';
import portrait from '../../media/img/portrait.png';
import '../../css/profilecard.css';

export default function ProfileCard(props) {
	const dispatch = useDispatch();

	const profile = useSelector(s => s.user.profile);

	async function handleGoogleLogin(res) {
		const idToken = res.getAuthResponse().id_token;

		try {
			await getAuthGoogle(idToken);
			
			const basicProfile = res.getBasicProfile();

			dispatch(setProfile({
				name: basicProfile.getName(),
				pic: basicProfile.getImageUrl()
			}));
		}
		catch(e) {
			console.warn(e);
			dispatch(clearUser());
		}
	}

	function handleGoogleLogout() {
		dispatch(clearUser());
	}

	function handleStatsClick() {
		playAudio('click');
		dispatch(setStatsModalOpen(true));
	}

	return (
		<div id="profile-card" className="card">
			<h3 id="profile-name">{profile.name || 'guest'}</h3>
			<img id="profile-pic" src={profile.pic || portrait} alt="profile pic"/>
			<div id="profile-stats">
				<LevelBar/>
				<QuestBar/>
			</div>
			<div id="profile-signin">
				{
					props.useGoogle ?
					(profile.name ?
					<GoogleLogout
						clientId="419414756092-k9drtqobcn3eq4c90uvaljs17eoidsd1.apps.googleusercontent.com"
						theme="dark"
						buttonText="sign out"
						isSignedIn={true}
						onLogoutSuccess={handleGoogleLogout}
						className="g-btn"
					/>
					:
					<GoogleLogin 
						clientId="419414756092-k9drtqobcn3eq4c90uvaljs17eoidsd1.apps.googleusercontent.com"
						theme="dark"
						buttonText="sign in"
						cookiePolicy={"single_host_origin"}
						isSignedIn={true}
						onSuccess={handleGoogleLogin}
						className="g-btn"
					/>) : null
				}
			</div>
			<button id="stats-btn" className="primary-btn icon-btn" onClick={handleStatsClick} aria-label="stats">{<IoMdStats/>}</button>
		</div>
	);
}