import React from 'react';
import ReactModal from 'react-modal';
import { playAudio } from '../../audio';
import { MdSettings } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsModalOpen, toggleMuteMusic, toggleMuteSFX } from '../../reducers/settingsReducer';

export default function SettingsModal() {
	const dispatch = useDispatch();

	const isOpen = useSelector(s => s.settings.isSettingsModalOpen);
	const isMuteMusic = useSelector(s => s.settings.isMuteMusic);
	const isMuteSFX = useSelector(s => s.settings.isMuteSFX);

	function handleToggleMusic() {
		dispatch(toggleMuteMusic());
	}

	function handleToggleSFX() {
		dispatch(toggleMuteSFX());
	}

	function handleClose() {
		dispatch(setSettingsModalOpen(false));
		playAudio('click');
	}

	return (
		<ReactModal isOpen={isOpen} className="modal" overlayClassName="overlay" contentLabel="Settings" onRequestClose={handleClose} closeTimeoutMS={100}>
			<h2>settings</h2>
			<MdSettings size={32} style={{position: 'absolute', top: 0, left: 0, transform: 'translate(-60%, -60%)'}}/>
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: 8}}>
				<div>mute music <input type="checkbox" checked={isMuteMusic} onChange={handleToggleMusic}/></div>
				<div>mute sfx <input type="checkbox" checked={isMuteSFX}  onChange={handleToggleSFX}/></div>
			</div>
			<button onClick={handleClose} style={{width: '100%', marginBottom: 0}}>close</button>
		</ReactModal>
	);
}