import { Howler, Howl } from 'howler';

import bgmMusic from './media/snd/this-sunny-day.mp3';

import popSnd from './media/snd/467951__benzix2__ui-button-click.mp3';
import clickSnd from './media/snd/394159__vacuumfan7072__uiclick5.mp3';
import cancelSnd from './media/snd/394160__vacuumfan7072__uiclick4.mp3';
import magicSnd from './media/snd/264981__renatalmar__sfx-magic.mp3';
import tilesSnd from './media/snd/350496__tris6970__plastic-tile.mp3';
import pingSnd from './media/snd/215415__unfa__ping.mp3';
import dragSnd from './media/snd/63532__florian-reinke__click2.mp3';
import dropSnd from './media/snd/63532__florian-reinke__click2-rev.mp3';
import hoverSnd from './media/snd/413310__tieswijnen__select.mp3';
import placeTileSnd from './media/snd/366065__simone-ds__chess-pieces.mp3';
import pointsSnd from './media/snd/482653__joao-janz__bonus-points-1-1.mp3';
import progressSnd from './media/snd/420505__jfrecords__uprising2.mp3';
import successSnd from './media/snd/171671__leszek-szary__success-1.mp3';
import fanfareSnd from './media/snd/270404__littlerobotsoundfactory__jingle-achievement-00.mp3';
import completeSnd from './media/snd/341578__kimp10__success.mp3';
import failSnd from './media/snd/150879__nenadsimic__jazzy-chords.mp3';
import tickingSnd from './media/snd/468081__kinoton__egg-timer-ticking-ringing.mp3';
import openSnd from './media/snd/422535__killersmurf96__menu-flick-sound.mp3';
import notifySnd from './media/snd/162473__kastenfrosch__successful.mp3';
import lvlupSnd from './media/snd/430925__higgs01__yipee.mp3';
import stampSnd from './media/snd/stamp.mp3';

const gain = 1.25;

let audio = null;

let isMuteMusic = false;
let isMuteSFX = false;

export const bgm = new Howl({
	src: [bgmMusic],
	volume: 0,
	loop: true,
	preload: false,
	onunlock: () => bgm.load(),
	onload: () => window.location.pathname === '/' && !bgm.playing() ? bgm.play() : null,
	onplay: () => bgm.fade(0, 0.04 * gain, 2000),
	onfade: () => {
		if(bgm.volume() === 0) {
			bgm.pause();

			if(window.location.pathname === '/')
				bgm.play();
		}
	}
});

window.onfocus = () => {
	Howler.mute(false);
	window.scrollTo(0, 0);
}

window.onblur = () => {
	Howler.mute(true);
}

window.onmousedown = () => {
	if(audio)
		return;

	audio = {
		click: new Howl({src: [clickSnd], volume: 0.25 * gain, preload: false}),
		pop: new Howl({src: [popSnd], volume: 0.25 * gain, preload: true}),
		cancel: new Howl({src: [cancelSnd], volume: 0.25 * gain, preload: false}),
		magic: new Howl({src: [magicSnd], volume: 0.1 * gain, preload: true}),
		tiles: new Howl({src: [tilesSnd], volume: 0.2 * gain, preload: false}),
		ping: new Howl({src: [pingSnd], volume: 0.2 * gain, preload: false}),
		drag: new Howl({src: [dragSnd], volume: 0.2 * gain, preload: false}),
		drop: new Howl({src: [dropSnd], volume: 0.2 * gain, preload: false}),
		hover: new Howl({src: [hoverSnd], volume: 0.25 * gain, preload: false}),
		placeTile: new Howl({src: [placeTileSnd], volume: 0.5 * gain, preload: false}),
		points: new Howl({src: [pointsSnd], volume: 0.4 * gain, preload: true}),
		progress: new Howl({src: [progressSnd], volume: 0.4 * gain, preload: false}),
		success: new Howl({src: [successSnd], volume: 0.1 * gain, preload: false}),
		fanfare: new Howl({src: [fanfareSnd], volume: 0.1 * gain, preload: false}),
		complete: new Howl({src: [completeSnd], volume: 0.25 * gain, preload: false}),
		fail: new Howl({src: [failSnd], volume: 0.1 * gain, preload: false}),
		ticking: new Howl({src: [tickingSnd], volume: 0.75 * gain, preload: false}),
		open: new Howl({src: [openSnd], volume: 0.5 * gain, preload: false}),
		notify: new Howl({src: [notifySnd], volume: 0.2 * gain, preload: false}),
		lvlup: new Howl({src: [lvlupSnd], volume: 0.5 * gain, preload: false}),
		stamp: new Howl({src: [stampSnd], volume: 0.05 * gain, preload: true})
	}

	for(let snd in audio) {
		const sndObj = audio[snd];

		if(!sndObj._preload) {
			sndObj.once('load', () => sndObj.play());
		}
	}
}

export function muteMusic(mute) {
	if(mute === isMuteMusic)
		return;

	bgm.mute(mute);

	if(mute)
		bgm.stop();
	else if(!bgm.playing() && bgm.state() === 'loaded' && window.location.pathname === '/')
		bgm.play();

	isMuteMusic = mute;
}

export function muteSFX(mute) {
	isMuteSFX = mute;
}

export function getAudio(name) {
	if(audio && name in audio)
		return audio[name];
	
	return null;
};

export function playAudio(name, rate = 1) {
	const audio = getAudio(name);

	if(audio && !isMuteSFX) {
		if(audio.state() === 'unloaded')
			audio.load();
		else
			audio.rate(rate, audio.play());
	}
}