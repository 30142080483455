import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { playAudio } from '../../audio';
import { requestNotifications } from '../../notifications';
import ConfirmModal from './ConfirmModal';
import { setNotificationsModalOpen } from '../../reducers/settingsReducer';

export default function NotificationsModal() {
	const dispatch = useDispatch();
	const isOpen = useSelector(s => s.settings.isNotificationsModalOpen);

	function handleOpen() {
		playAudio('notify');
	}

	async function handleConfirm() {
		try {
			if('Notification' in window && Notification.permission !== 'granted')
				await Notification.requestPermission();
				
			requestNotifications(true);
		}
		catch(e) {
			console.warn(e);
		}

		playAudio('stamp');

		dispatch(setNotificationsModalOpen(false));
	}

	function handleCancel() {
		dispatch(setNotificationsModalOpen(false));
	}

	return (
		<ConfirmModal isOpen={isOpen} onOpen={handleOpen} onCancel={handleCancel} onConfirm={handleConfirm} title="stay notified" text={"would you like to be notified\nwhen your games and turns begin?"} tip="(you may close the tab, we'll update you)" cancelText="pass" confirmText="sure, notify me" modalClass="modal-wide"/>
	);
}