import config from './config';
import { subscribe, unsubscribe } from './apis/api';

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
	  .replace(/-/g, '+')
	  .replace(/_/g, '/');
   
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
   
	for (let i = 0; i < rawData.length; ++i) {
	  outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
  }

export async function requestNotifications(prompt = true, onlyCreate = false) {
	if('Notification' in window) {
		try {
			if('serviceWorker' in navigator) {					
				const swr = await navigator.serviceWorker.ready;
				const sub = await swr.pushManager.getSubscription();

				if(onlyCreate && sub)
					return;

				if(prompt || Notification.permission === 'granted') {
					const newSub = sub ? sub : await swr.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: urlBase64ToUint8Array(config.PUSH_PUBLIC_KEY)
					});

					await subscribe(newSub);
				}
			}
		}
		catch(e) {
			console.warn(e);
		}
	}
}

export async function cancelNotifications() {
	try {
		if('serviceWorker' in navigator) {					
			const swr = await navigator.serviceWorker.ready;
			const sub = await swr.pushManager.getSubscription();

			if(sub)
				await unsubscribe(sub);
		}
	}
	catch(e) {
		console.warn(e);
	}
}