import React, { useState, useRef } from 'react';
import { useMedia, useMount } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setRegion, setCount, setLeaders, setNickname } from '../reducers/userReducer';
import { setSettingsModalOpen, setLeaderboardModalOpen, setLearnModalOpen } from '../reducers/settingsReducer';
import ProfileCard from './cards/ProfileCard';
import GamesCard from './cards/GamesCard';
import { getMeta, createRoom } from '../apis/api';
import { bgm, playAudio } from '../audio';
import config, { rulesets } from '../config';
import { MdSettings } from 'react-icons/md';
import { FaGraduationCap } from 'react-icons/fa';
import { GrTwitter } from 'react-icons/gr';
import { isIframe } from './App';
import logo from '../media/img/logos/logo.svg';
import cglogo from '../media/img/logos/crazygames.png';
import iologo from '../media/img/logos/iogames.svg';
import '../css/home.css';

const asyncImportGame = () => import('./Game');

export default function Home(props) {
	const history = useHistory();
	const dispatch = useDispatch();

	const isMobile = useMedia('(max-width: 768px)');

	const nickname = useSelector(s => s.user.nickname);
	const count = useSelector(s => s.user.count);
	const userRooms = useSelector(s => s.user.rooms);
	const isMuteMusic = useSelector(s => s.settings.isMuteMusic);

	const [nameInput, setNameInput] = useState(nickname);
	const starting = useRef(false);

	useMount(() => {
		if(!bgm.playing() && bgm.state() === 'loaded' && !isMuteMusic)
			bgm.play();

		getMeta().then(meta => {
			dispatch(setRegion(meta.region));
			dispatch(setCount(meta.count));
			dispatch(setLeaders(meta.leaders));
		}).catch(console.warn);
	});

	async function start(ruleset) {
		playAudio('click');

		if(starting.current)
			return;
		
		starting.current = true;

		try {
			asyncImportGame();
			
			dispatch(setNickname(nameInput));

			const waitingRoom = Object.values(userRooms).find(r => r.setupData.ruleset === ruleset && !r.isGameOver && r.players.filter(p => p.name).length <= 1);

			if(config.RULES[ruleset].PUBLIC && waitingRoom)
				history.push('/g/' + waitingRoom.roomId);
			else {
				const res = await createRoom(ruleset);
				history.push('/g/' + res.room.roomId, {preload: ruleset === rulesets.STANDARD});
			}
		}
		catch(e) {
			console.error(e);
		}

		starting.current = false;
	}

	function handlePlayClick() {
		start(rulesets.STANDARD);
	}

	function handlePlayFriendClick() {
		start(rulesets.FRIENDLY);
	}

	function handlePlayPartyClick() {
		start(rulesets.PARTY);
	}

	function handleLeaderboardClick() {
		dispatch(setLeaderboardModalOpen(true));
		playAudio('click');
	}

	function handleHowToClick() {
		dispatch(setLearnModalOpen(true));
		playAudio('click');
	}

	function handleTwitterClick() {
		window.open('https://twitter.com/tomkilsby_', '_blank');
	}

	function handleSettingsClick() {
		dispatch(setSettingsModalOpen(true));
		playAudio('click');
	}

	function handleCrazyClick() {
		window.open('https://www.crazygames.com/c/io', '_blank');
	}

	function handleIOClick() {
		window.open('https://iogames.space', '_blank');
	}
	
	function handleTagClick() { 
		//window.open('https://play.google.com/store/apps/details?id=io.points4words.twa', '_blank');
	}

	const tag = count >= 10 ? `${count} playing now!` : 'All the words!';

	return (
		<div id="home">
			<div id="title-wrapper">
				<div id="header">
					<img id="header-logo" src={logo} alt="logo"/>
					<span id="wip-tag" className="info-tag" style={{width: 128}} onClick={handleTagClick}>{tag}</span>
				</div>
			</div>
			<div id="menu">
				<div id="cards">
					<div id="home-profile-card">
						<ProfileCard useGoogle={!isMobile}/>
					</div>
					<div className="card">
						<input id="play-btn-ok" className="primary-input" type="text" placeholder="nickname..." value={nameInput} onChange={(e) => setNameInput(e.target.value)} maxLength={9} autoComplete="off" required/>
						<div id="play-controls">
							<button className="secondary-btn" style={{flexGrow: 1}} onClick={handlePlayClick}>play</button>
							<button className="secondary-btn" onClick={handlePlayFriendClick}>play a friend</button>
						</div>
						<button className="primary-btn" onClick={handlePlayPartyClick}>play party mode</button>
						<div id="meta-controls">
							<button style={{flexGrow: 1}} onClick={handleLeaderboardClick}>leaderboards</button>
							<button className="icon-btn" onClick={handleHowToClick}><FaGraduationCap size={32}/></button>
						</div>
					</div>
					<div id="home-games-card">
						<GamesCard/>
					</div>
				</div>
			</div>
			<a id="sponsor-hero" href="https://chordwatch.com" target="_blank">Learn Piano intuitively with Chordwatch.com ⧉</a>
			<button id="twitter-btn" className="icon-btn" onClick={handleTwitterClick} aria-label="twitter">{<GrTwitter/>}</button>
			<button id="settings-btn" className="icon-btn" onClick={handleSettingsClick} aria-label="settings">{<MdSettings/>}</button>
			{isIframe("crazygames") ? <button id="host-btn" className="icon-btn" onClick={handleCrazyClick} aria-label="host"><img style={{width: '75%', height: '75%'}} src={cglogo} alt="crazygames"/></button> : null}
			{isIframe("iogames") ? <button id="host2-btn" className="icon-btn" onClick={handleIOClick} aria-label="host"><img style={{width: '75%', height: '75%'}} src={iologo} alt="iogames"/></button> : null}
		</div>
	)
}
