import store from '../store';
import config from '../config';
import { setUser, clearUser, patchRooms, setBot } from '../reducers/userReducer';

const api = config.URI + (config.ENV === 'dev' ? '/dev' : '');

export async function subscribe(sub) {
	const user = store.getState().user;

	if(!user.id)
		return;

	const res = await fetch(api + '/sub', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + btoa(user.id + ':' + user.token)
		},
		body: JSON.stringify(sub)
	});

	if(res.status === 401) {
		throw new Error('Not authorized!');
	}
}

export async function unsubscribe(sub) {
	await fetch(api + '/sub', {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(sub)
	});
}

export async function getAuthGoogle(idToken) {
	const user = store.getState().user;

	const headers = {
		'Content-Type': 'application/json'
	}

	if(user.id)
		headers['Authorization'] = 'Basic ' + btoa(user.id + ':' + user.token);

	const res = await fetch(api + '/authGoogle', {
		method: 'POST',
		headers: headers,
		body: JSON.stringify({idToken})
	});

	if(res.status === 401) {
		throw new Error('Not authorized!');
	}

	const data = await res.json();

	if(data.user)
		store.dispatch(setUser(data.user));

	return data;
}

export async function getMeta() {
	const headers = {
		'Content-Type': 'application/json'
	}

	const res = await fetch(api + '/meta?count=true', {
		method: 'GET',
		headers: headers
	});

	return res.json();
}

export async function createRoom(ruleset) {
	const user = store.getState().user;

	const headers = {
		'Content-Type': 'application/json'
	}

	if(user.id)
		headers['Authorization'] = 'Basic ' + btoa(user.id + ':' + user.token);

	const res = await fetch(api + '/rooms', {
		method: 'POST',
		headers: headers,
		body: JSON.stringify({ruleset, nickname: user.nickname})
	});

	if(res.status === 401) {
		store.dispatch(clearUser());

		throw new Error('Not authorized!');
	}

	const data = await res.json();

	if(data.user)
		store.dispatch(setUser(data.user));

	if(data.room) {
		const userRooms = store.getState().user.rooms;

		if(!(data.room.roomId in userRooms))
			store.dispatch(patchRooms({[data.room.roomId]: data.room}));
	}

	return data;
}

export async function joinRoom(roomId, isBot = false) {
	const state = store.getState();
	const user = isBot ? state.user.bot : state.user;

	const headers = {
		'Content-Type': 'application/json'
	}

	if(user.id)
		headers['Authorization'] = 'Basic ' + btoa(user.id + ':' + user.token);

	const res = await fetch(api + '/rooms/' + roomId, {
		method: 'PATCH',
		headers: headers,
		body: JSON.stringify({nickname: user.nickname, isBot})
	});

	if(res.status === 401) {
		store.dispatch(clearUser());
		throw new Error('Not authorized!');
	}

	const data = await res.json();

	if(data.user)
		store.dispatch(isBot ? setBot(data.user) : setUser(data.user));

	return data;
}

export async function rematchRoom(roomId) {
	const user = store.getState().user;

	if(!user.id)
		return;

	const res = await fetch(api + '/rooms/' + roomId + '/rematch', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + btoa(user.id + ':' + user.token)
		}
	});

	if(res.status === 401) {
		store.dispatch(clearUser());
		throw new Error('Not authorized!');
	}

	const data = await res.json();

	if(data.room) {
		const userRooms = store.getState().user.rooms;

		if(!(data.room.roomId in userRooms))
			store.dispatch(patchRooms({[data.room.roomId]: data.room}));
	}

	return data;
}

export async function leaveRoom(roomId) {
	const user = store.getState().user;

	if(!user.id)
		return;

	store.dispatch(patchRooms({[roomId]: null}));

	const res = await fetch(api + '/rooms/' + roomId, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Basic ' + btoa(user.id + ':' + user.token)
		}
	});

	if(res.status === 401) {
		store.dispatch(clearUser());
		throw new Error('Not authorized!');
	}
}

export async function getRoom(roomId) {
	const user = store.getState().user;

	const headers = {
		'Content-Type': 'application/json'
	}

	if(user.id)
		headers['Authorization'] = 'Basic ' + btoa(user.id + ':' + user.token);

	const res = await fetch(api + '/rooms/' + roomId, {
		method: 'GET',
		headers: headers
	});

	if(res.status === 401) {
		store.dispatch(clearUser());
		throw new Error('Not authorized!');
	}

	return res.json();
}

export async function getRooms(force = false, signal = null) {
	const user = store.getState().user;

	if(!user.id)
		throw new Error('No user!');

	const res = await fetch(api + '/rooms' + (force ? '?force=1' : ''), {
		method: 'GET',
		signal,
		headers: {
			'Authorization': 'Basic ' + btoa(user.id + ':' + user.token)
		}
	});

	if(res.status === 401) {
		store.dispatch(clearUser());
		throw new Error('Not authorized!');
	}

	return res.json();
}

export async function setPing() {
	const user = store.getState().user;

	if(!user.id)
		throw new Error('No user!');

	const res = await fetch(api + '/ping', {
		method: 'HEAD',
		headers: {
			'Authorization': 'Basic ' + btoa(user.id + ':' + user.token)
		}
	});

	if(res.status === 401) {
		store.dispatch(clearUser());
		throw new Error('Not authorized!');
	}
}

export async function clearPing() {
	const user = store.getState().user;

	if(!user.id)
		throw new Error('No user!');

	const res = await fetch(api + '/ping', {
		method: 'DELETE',
		headers: {
			'Authorization': 'Basic ' + btoa(user.id + ':' + user.token)
		}
	});

	if(res.status === 401) {
		store.dispatch(clearUser());
		throw new Error('Not authorized!');
	}
}

const defCache = {};

export async function defineWord(word) {
	if(word in defCache)
		return defCache[word];

	defCache[word] = [];

	try {
		const res = await fetch(api + '/define/' + word);
		defCache[word] = await res.json();
	}
	catch(e) {
		defCache[word] = [];
	}

	return defCache[word];
}
