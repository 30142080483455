import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
	name: 'settings',
	initialState: {
		isMenuOpen: false,
		isMuteMusic: false,
		isMuteSFX: false,
		isSettingsModalOpen: false,
		isLearnModalOpen: false,
		isStatsModalOpen: false,
		isNotificationsModalOpen: false,
		isLeaderboardModalOpen: false,
		isGameOverModalOpen: false
	},
	reducers: {
		setMenuOpen: (s, a) => { s.isMenuOpen = a.payload },
		toggleMuteMusic: s => { s.isMuteMusic = !s.isMuteMusic },
		toggleMuteSFX: s => { s.isMuteSFX = !s.isMuteSFX },
		setSettingsModalOpen: (s, a) => { s.isSettingsModalOpen = a.payload },
		setLearnModalOpen: (s, a) => { s.isLearnModalOpen = a.payload },
		setStatsModalOpen: (s, a) => { s.isStatsModalOpen = a.payload },
		setLeaderboardModalOpen: (s, a) => { s.isLeaderboardModalOpen = a.payload },
		setGameOverModalOpen: (s, a) => { s.isGameOverModalOpen = a.payload },
		setNotificationsModalOpen: (s, a) => {
			if(!window.promptedNotify || !a.payload) {
				s.isNotificationsModalOpen = a.payload;

				if(a.payload)
					window.promptedNotify = true;
			}
		}
	}
});

export const { setMenuOpen, toggleMuteMusic, toggleMuteSFX, setSettingsModalOpen, setLearnModalOpen, setStatsModalOpen, setGameOverModalOpen, setLeaderboardModalOpen, setNotificationsModalOpen } = settingsSlice.actions;

export default settingsSlice.reducer;