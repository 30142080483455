import standard from './standard';

const rules = {
	...standard,
	PUBLIC: false,
	TIMEOUT: -1,
	VOTE_TIMEOUT: -1,
	PLAY_ORDER: ['0', '1'],
	WIN_XP: 0,
	DRAW_XP: 0
}

export default rules;